export const positions = [
  "모험가",
  "프론트엔드",
  "백엔드",
  "iOS",
  "안드로이드",
  "보안",
  "데이터/AI",
  "임베디드",
  "게임",
  "그래픽스",
  "디자인",
  "기타",
];

export const status = ["휴식중", "프로젝트 찾는 중"];
